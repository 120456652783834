<template>
    <el-dialog title="分配用户" :visible.sync="dialogVisible" top="0vh" center :close-on-click-modal="false" width="1200px">
        <el-form :inline="true" :model="assignUserDto" size="mini">
            <el-form-item label="登录名" prop="loginName">
                <el-input v-model="assignUserDto.loginName" size="mini" placeholder="请输入登录名" />
            </el-form-item>
            <el-form-item label="用户名" prop="userName">
                <el-input v-model="assignUserDto.userName" size="mini" placeholder="请输入用户名" />
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
                <el-input v-model="assignUserDto.email" size="mini" placeholder="请输入邮箱" />
            </el-form-item>
            <el-form-item label="电话" prop="phone">
                <el-input v-model="assignUserDto.phone" size="mini" placeholder="请输入电话" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini" @click="queryStationAssignUserList">查询</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="tableData" border size="small" stripe height="550px"
            :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="tableLoading">
            <el-table-column prop="orgName" label="归属机构" />
            <el-table-column prop="loginName" label="登录名" width="200"/>
            <el-table-column prop="userName" label="用户名" width="200"/>
            <el-table-column prop="email" label="邮箱" width="300"/>
            <el-table-column prop="phone" label="电话" width="100" />
            <el-table-column prop="isAssign" label="分配" width="80" align="center">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.isAssign"
                        :active-value="true"
                        :inactive-value="false"
                        @change="handleAssignUserChangeEvent(scope.row)">
                    </el-switch>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination">
            <el-pagination background
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableTotal"
                :current-page.sync="assignUserDto.pageNum"
                :page-sizes="[10, 50, 100]"
                :page-size="assignUserDto.pageSize"
                @size-change="handleSizeChangeEvent"
                @current-change="handleCurrentChangeEvent"/>
        </div>
    </el-dialog>
</template>
<script>
import * as stationInfoApi from "@/api/esaio/station/esaioStationInfoApi";
import global from "@/components/Global";
export default {

    name: "StationAssignUser",
    data() {

        return {

            dialogVisible: false,
            assignUserDto: {

                stationId: 0,
                loginName: '',
                userName: '',
                email: '',
                phone: '',
                pageNum: 1,
                pageSize: 10
            },
            tableLoading: false,
            tableData: [],
            tableTotal: 0
        };
    },
    methods: {

        openAssignUserPage(id) {

            this.dialogVisible = true;
            this.assignUserDto.stationId = id;
            this.queryStationAssignUserList();
        },
        queryStationAssignUserList() {

            this.tableLoading = true;
            stationInfoApi.queryStationAssignUserList(this.assignUserDto).then(res => {

                this.tableLoading = false;
                if (res.code === global.response_status_success_obj) {

                    this.tableData = res.data.result;
                    this.tableTotal = res.data.total;
                }
            }).catch(err => {

                this.tableLoading = false;
                console.error(err);
            });
        },
        handleSizeChangeEvent(val) {

            this.assignUserDto.pageSize = val;
            this.queryStationAssignUserList();
        },
        handleCurrentChangeEvent(val) {

            this.assignUserDto.pageNum = val;
            this.queryStationAssignUserList();
        },
        handleAssignUserChangeEvent(row) {

            let params = {

                stationId: this.assignUserDto.stationId,
                userId: row.userId,
                isAssign: row.isAssign,
                id: row.id
            }
            stationInfoApi.assignUsersToStations(params).then(res => {

                if (res.code === global.response_status_success_obj) {

                    this.queryStationAssignUserList();
                }
            });
        }
    }
}
</script>
<style scoped lang="scss">
</style>