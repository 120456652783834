<template>
    <div class="panel-style">
        <el-form :inline="true" :model="searchDto" size="mini">
            <el-form-item label="归属机构">
                <doart-select-tree :width="190" :data="orgData" :checkedKeys="orgCheckedKeys" :clearable="true"
                    @change="handleSelectOrgChangeEvent"/>
            </el-form-item>
            <el-form-item label="站点名称">
                <el-input v-model="searchDto.name" size="mini" clearable placeholder="站点名称"/>
            </el-form-item>
            <el-form-item label="归属地区">
                <el-cascader :options="areaOptions" :props="areaProps" @change="searchAreaChange"
                    clearable size="mini" placeholder="全部"/>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleQueryClickEvent" size="mini">查询</el-button>
                <el-button type="primary" @click="openAddEsaioStationInfoPage" size="mini">新增</el-button>
            </el-form-item>
        </el-form>
        <el-table border stripe size="small" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            :data="tableData" height="550px" v-loading="listLoading">
            <el-table-column prop="name" label="站点名称" min-width="130px"/>
            <el-table-column prop="orgName" label="所属机构" min-width="130px"/>
            <el-table-column prop="nationCn" label="所属国家" width="130px" align="center"/>
            <el-table-column prop="provCn" label="所属省" width="180px" align="center"/>
            <el-table-column prop="cityCn" label="所属市（区）" width="180px" align="center"/>
            <el-table-column prop="createTime" label="创建时间" width="150px" align="center"/>
            <el-table-column prop="createByName" label="创建人" width="180px" :formatter="createByNameFormatter"/>
            <el-table-column label="操作" width="100px" align="center">
                <template slot-scope="scope">
                    <div class="option-btn-div">
                        <el-tooltip effect="dark" content="编辑" placement="top">
                            <i class="el-icon-edit option-btn-normal" @click="openModifyEsaioStationInfoPage(scope.row.id)"/>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="分配用户" placement="top">
                            <i class="el-icon-user option-btn-normal" @click="openAssignUserForEsaioStationPage(scope.row.id)"/>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="删除" placement="top">
                            <i class="el-icon-delete option-btn-normal" style="color: #F56C6C" @click="removeEsaioStationInfo(scope.row.id)"/>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination">
            <el-pagination background layout="total, sizes, prev, pager, next, jumper"
                :total="tableTotal"
                :current-page.sync="searchDto.pageNum"
                :page-sizes="[10, 50, 100]"
                :page-size="searchDto.pageSize"
                @size-change="handleSizeChangeEvent"
                @current-change="handleCurrentChangeEvent"/>
        </div>
        <station-info-detail ref="stationInfoDetail" @queryEsaioStationInfoList="queryEsaioStationInfoList"/>
        <station-assign-user ref="stationAssignUser" />
    </div>
</template>
<script>
import global from "@/components/Global";
import * as stationInfoApi from "@/api/esaio/station/esaioStationInfoApi";
import StationInfoDetail from "@/views/esaio/station/station-info-detail";
import StationAssignUser from "@/views/esaio/station/station-assign-user";
import DoartSelectTree from "@/components/doart-select-tree/doart-select-tree";
import message from "@/utils/message";

export default {

    name: "StationInfoList",
    components: {

        StationInfoDetail,
        DoartSelectTree,
        StationAssignUser
    },
    data() {

        return {

            orgData : [],
            orgCheckedKeys: [],
            areaOptions: [],
            areaProps: {

                value: 'areaKey',
                label: 'areaName',
                children: 'subAreas',
                checkStrictly: true
            },
            searchDto: {

                pageNum: 1,
                pageSize: 10,
                orgId: '',
                name: '',
                areaName: '',
                areaLevel: ''
            },
            tableData: [],
            tableTotal: 0,
            listLoading: false
        }
    },
    mounted() {

        this.queryOrgInfo();
        this.queryAreaInfo();
        this.queryEsaioStationInfoList();
    },
    methods: {

        handleSelectOrgChangeEvent(orgId) {

            this.searchDto.orgId = orgId;
        },
        searchAreaChange(value) {

            if (value && value.length > 0) {

                this.searchDto.areaLevel = value.length;
                this.searchDto.areaName = value[value.length - 1];
            } else {

                this.searchDto.areaLevel = '';
                this.searchDto.areaName = '';
            }
        },
        queryOrgInfo() {

            return new Promise((resolve, reject) => {

                stationInfoApi.queryCurUserEsaioStationOrgTree().then((res) => {

                    if (res.code === global.response_status_success_obj) {

                        this.orgData = [res.data];
                        resolve(res.msg);
                    } else {

                        reject(res.msg);
                    }
                }).catch(err => {

                    reject('请求失败,请稍后再试');
                    console.error(err);
                });
            });
        },
        queryAreaInfo() {

            stationInfoApi.queryEsaioStationArea().then(res => {

                if (res.code === global.response_status_success_obj) {

                    this.areaOptions = res.data;
                }
            });
        },
        queryEsaioStationInfoList() {

            this.listLoading = true;
            stationInfoApi.queryEsaioStationInfoList(this.searchDto).then(res => {

                this.listLoading = false;
                if (res.code === global.response_status_success_obj) {

                    this.tableTotal = res.data.total;
                    this.tableData = res.data.result;
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
                this.listLoading = false;
            });
        },
        handleSizeChangeEvent(val) {

            this.searchDto.pageSize = val;
            this.queryEsaioStationInfoList();
        },
        handleCurrentChangeEvent(val) {

            this.searchDto.pageNum = val;
            this.queryEsaioStationInfoList();
        },
        handleQueryClickEvent() {

            this.searchDto.pageNum = 1;
            this.queryEsaioStationInfoList();
        },
        openAddEsaioStationInfoPage() {

            this.$refs.stationInfoDetail.openAddEsaioStationInfoPage();
        },
        openModifyEsaioStationInfoPage(id) {

            this.$refs.stationInfoDetail.openModifyEsaioStationInfoPage(id);
        },
        removeEsaioStationInfo(id) {

            this.$confirm('是否需要删除？', '提示', {cancelButtonClass: "btn-custom-cancel"}).then(() => {

                let params = {

                    id: id
                };
                stationInfoApi.removeEsaioStationInfo(params).then(res => {

                    if (res.code === global.response_status_success_obj) {

                        this.queryEsaioStationInfoList();
                        message.success('删除成功');
                    } else {

                        message.error(res.msg);
                    }
                });
            });
        },
        openAssignUserForEsaioStationPage(id) {

            this.$refs.stationAssignUser.openAssignUserPage(id);
        },
        createByNameFormatter(row, column, cellValue) {

            if (!cellValue) {

                return '--';
            }
            return cellValue;
        }
    }
}
</script>
<style scoped lang="scss">
.pagination {

    margin-top: 15px;
    text-align: right;
}
.option-btn-div {

    display: flex;
    justify-content: space-around
}
.option-btn-normal {

    font-size: 18px;
    color: #409EFF;
    cursor: pointer;
}
</style>